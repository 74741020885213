import React from "react"
import { AnimatePresence } from "framer-motion"

// If exitBeforeEnter set to true, AnimatePresence will only render one component at a time.
// The exiting component will finished its exit animation before the entering component is rendered.
// 7.2.0 change = exitBeforeEnter deprecated - replace with mode="wait"
export const wrapPageElement = ({ element }) => (
  // <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
  <AnimatePresence mode="wait">{element}</AnimatePresence>
)

// Wait until page exit animation has completed before updating scroll position
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const transitionDelay = 300 //0.3s

  // if it's a "normal" route
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  }
  // if we used the browser's forwards or back button
  else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  return false
}
